import { Language } from './language';

const fr = {
    code: Language.fr,

	// HOME PAGE
	
	t_description: 'Fille batteuse de {age} ans',
	t_close: 'Fermer',

	b_home: 'Accueil',
	b_about: 'Sur moi',
	b_press: 'Presse',
	b_shows: 'Montre',
	b_soultone: 'Soultone',
	b_language: 'Français',
	b_share: 'Partager',
	b_scroll_to_top: 'Faites défiler vers le haut',

	t_english: 'Anglaise',
	t_spanish: 'Espagnole',
	t_portuguese: 'Portugais',
	t_italian: 'Italien',
	t_french: 'Français',
	t_german: 'Allemand',
	t_chinese: 'Chinois',

	// SHOWS PAGE

	ClassicMetal_title: 'Métal classique',
	ClassicMetal_description: 'School of Rock Seattle',

	StAlsGrandDay2024_title: 'Performance du Grand Jour 2024',
	StAlsGrandDay2024_description: 'St. Alphonsus Parish School Seattle',

	BlackViolinConcert_title: 'Concert De Violon Noir Avec Le Monde Votre Orchestre',
	BlackViolinConcert_description: 'Le Théâtre Paramount',

	MexicanIndependenceDay2024_title: 'Célébration du Jour de l\'Indépendance du Mexique avec le Ballard Civic Orchestra',
	MexicanIndependenceDay2024_description: 'Hôtel de ville de Seattle',

	BluesRock_title: 'Blues Rock',
	BluesRock_description: 'School of Rock Seattle',

	BallardSeaFoodFest2024_title: 'Festival des fruits de mer Ballard 2024',
	BallardSeaFoodFest2024_description: 'Festival des fruits de mer Ballard',

	Funk_title: 'Funk',
	Funk_description: 'School of Rock Seattle',

	Heart_title: 'Heart',
	Heart_description: 'School of Rock Seattle',
	
	ModernMetal_title: 'Métal moderne',
	ModernMetal_description: 'School of Rock Seattle',

	SeasonPreviewWinter2023_title: 'Aperçu de la saison hiver 2023',
	SeasonPreviewWinter2023_description: 'School of Rock Seattle',

	SmashingPumpkins_title: 'Smashing Pumpkins',
	SmashingPumpkins_description: 'School of Rock Seattle',

	Nirvana_title: 'Nirvana',
	Nirvana_description: 'School of Rock Seattle',

	HairMetal_title: 'Cheveux Métal',
	HairMetal_description: 'School of Rock Seattle',

	TalentShow2023_title: 'Spectacle de talents 2023',
	TalentShow2023_description: 'Seattle Salmon Bay K-8',

	Deftones_title: 'Deftones',
	Deftones_description: 'School of Rock Seattle',

	SeasonPreviewSpring2023_title: 'Aperçu de la saison printemps 2023',
	SeasonPreviewSpring2023_description: 'School of Rock Seattle',

	Ramones_title: 'Les Ramones',
	Ramones_description: 'School of Rock Seattle',

	Soundtrack_title: 'Spectacle de la bande originale',
	Soundtrack_description: 'School of Rock Seattle',

	PopPunkVsEmo_title: 'Pop Punk Vs. Emo',
	PopPunkVsEmo_description: 'School of Rock Seattle',

	_90sNostalgia_title: 'Nostalgie des années 90',
	_90sNostalgia_description: 'School of Rock Seattle',

	TalentShow2022_title: 'Spectacle de talents 2022',
	TalentShow2022_description: 'Seattle Salmon Bay K-8',

	// PRESS PAGE

	b_watch_here: 'Regardez ici',

	the_daly_migs_show_interview_title: 'L\'interview du Daly Migs Show',
	the_daly_migs_show_interview_description: 'Interview sur le fait d\'être invité à rejoindre Godsmack sur scène',

	alizee_defan_07_02_2024_title: 'Entretien avec "Alizée Defan"',
	alizee_defan_07_02_2024_description: 'Interview exclusive',

	jaime_mendez_news_06_26_2024_title: 'Interview "Jaime Mendez Nouvelles"',
	jaime_mendez_news_06_26_2024_description: 'Nouvelles locales de Seattle en espagnol',

	bien_informado_03_22_2023_title: 'Article de "Bien Informado"',
	bien_informado_03_22_2023_description: 'Article du site d\'information argentin sur Victoria',

	wave_potter_reaction_01_30_2023_title: 'La réaction de Wave Potter',
	wave_potter_reaction_01_30_2023_description: 'Wave Potter réagit à la reprise de Victoria Lux Æterna de Metallica',

	// ABOUT PAGE

	b_back: 'Retourner',

	t_title: 'Victoria Acosta',
	t_pronouns: '(elle)',

	b_read_more: 'En savoir plus',
	b_read_less: 'Lire moins',

	t_about: `
Rencontrez Victoria Acosta, la batteuse électrisante de {age} ans, dont l'énergie vibrante et la passion implacable pour la musique conquièrent le monde ! Née à El Paso, Texas, avec des racines à Ciudad Juárez, Mexique, elle a grandi dans les rues animées de Guadalajara. Aujourd'hui, elle vit à Seattle, Washington, et continue d'inspirer le public avec son talent exceptionnel et son dévouement à son art.

La fascination de Victoria pour la batterie a pris naissance dès l'âge de quatre ans. Pendant que la plupart des enfants jouaient avec des jouets, cette jeune talentueuse était occupée à taper des rythmes et à déclarer à sa famille que la batterie était son instrument préféré.

Le 13 septembre 2018, son rêve est devenu réalité lorsqu'elle a déballé sa première batterie, et depuis ce moment-là, rien ne l'a arrêtée ! Elle s'est inscrite à la prestigieuse Musicalerias Academy à Guadalajara, s'immergeant pleinement dans des cours formels, maîtrisant l'art du rythme comme une véritable star du rock.

L'histoire de Victoria a pris un nouveau tournant lorsque sa famille a déménagé à Seattle le 10 octobre 2019. Inébranlable, elle a poursuivi sa formation en ligne, prouvant que la distance n'était pas un obstacle pour une musicienne passionnée. Son grand tournant est arrivé le 29 mai 2022, lorsqu'elle a pris une leçon d'essai à la célèbre School of Rock. Tandis que la plupart de ses camarades commençaient dans le programme de base Rock 101, le talent de Victoria a brillamment émergé, lui permettant d'intégrer le programme avancé Performers Program. Depuis, elle a ébloui le public dans {shows} concerts en direct avec son énergie contagieuse et sa présence magnétique sur scène.

Mais ce n'est que le début ! Victoria n'est pas seulement une interprète, elle est également une sensation des réseaux sociaux, ayant enregistré des centaines de vidéos captivantes montrant son incroyable talent à la batterie. Le 13 juin 2024, elle a fait sensation en tant que batteuse invitée pour le compositeur italien acclamé Alberto Rigonni lors du Song Symposium pour l'album Nemesis Call, un témoignage de son ascension fulgurante !

Dans un tournant excitant, Victoria a signé un contrat de partenariat avec Soultone Cymbals le 12 septembre 2024, consolidant sa place parmi l'élite des batteurs et portant son son à un niveau supérieur.

Comme si cela ne suffisait pas, Victoria a élargi ses horizons musicaux en apprenant le violon en quatrième année. En rejoignant la World Youth Orchestra le 13 mai 2024, elle a eu l'honneur de se produire aux côtés du duo renommé Black Violin au Paramount Theatre, en émerveillant un public de 2 000 personnes avec son talent exceptionnel.

Mais son voyage ne s'est pas arrêté là ! Le 29 août 2024, elle a rejoint la Ballard Civic Orchestra en tant que percussionniste, faisant ses débuts au Town Hall de Seattle lors de la célébration de la fête de l'indépendance du Mexique devant près de 1 000 fans enthousiastes.

Les distinctions de Victoria sont aussi impressionnantes que ses performances. Elle a remporté le Prix d'Or et la distinction de Technique Fantastique lors du London Young Musician Contest 2022 et a été honorée avec le Prix du Meilleur Musicien de l'Année et le Prix du Meilleur Batteur lors des London Young Musician of the Year 2023.

Maintenant bien installée à Seattle, Victoria pratique tous les jours, repoussant les limites de ses compétences à la batterie et au violon. Avec les yeux rivés sur un avenir passionnant dans la musique, cette jeune artiste dynamique ne fait que commencer. Préparez-vous à être inspiré par Victoria Acosta, où le rythme rencontre la passion et où les rêves deviennent réalité !
`,

	// PRESS PAGE

	t_footer: 'Tous droits réservés',

		// SOULTONE: 

		b_visit_artist_page: 'Visitez ma page d\'artiste'

}

export default fr;
