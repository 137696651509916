import { Language } from './language';

const pt = {
    code: Language.pt,

	// HOME PAGE

	t_description: 'Menina baterista, {age} anos',
	t_close: 'Fechar',
	
	b_home: 'Início',
	b_about: 'Sobre mim',
	b_press: 'Imprensa',
	b_shows: 'Shows',
	b_soultone: 'Soultone',
	b_language: 'Português',
	b_share: 'Compartilhar',
	b_scroll_to_top: 'Vá para começar',

	t_english: 'Inglês',
	t_spanish: 'Espanhol',
	t_portuguese: 'Português',
	t_italian: 'Italiano',
	t_french :'Francês',
	t_german: 'Alemão',
	t_chinese: 'Chinês',

	// SHOWS PAGE

	ClassicMetal_title: 'Metal Clássico',
	ClassicMetal_description: 'School of Rock Seattle',

	StAlsGrandDay2024_title: 'Apresentação do Dia dos Avós 2024',
	StAlsGrandDay2024_description: 'Escola Santo Afonso em Seattle',

	BlackViolinConcert_title: 'Concerto de Violino Negro com The World Your Orchestra',
	BlackViolinConcert_description: 'O Teatro Paramount',

	MexicanIndependenceDay2024_title: 'Celebração da Independência do México com a Orquestra Cívica Ballard',
	MexicanIndependenceDay2024_description: 'Prefeitura de Seattle',

	BluesRock_title: 'Blues Rock',
	BluesRock_description: 'School of Rock Seattle',

	BallardSeaFoodFest2024_title: 'Festival de Frutos do Mar Ballard 2024',
	BallardSeaFoodFest2024_description: 'Festival de Frutos do Mar Ballard',

	Funk_title: 'Funk',
	Funk_description: 'School of Rock Seattle',

	Heart_title: 'Heart',
	Heart_description: 'School of Rock Seattle',

	ModernMetal_title: 'Metal Moderno',
	ModernMetal_description: 'School of Rock Seattle',

	SeasonPreviewWinter2023_title: 'Prévia da Temporada Inverno 2023',
	SeasonPreviewWinter2023_description: 'School of Rock Seattle',

	SmashingPumpkins_title: 'Smashing Pumpkins',
	SmashingPumpkins_description: 'School of Rock Seattle',

	Nirvana_title: 'Nirvana',
	Nirvana_description: 'School of Rock Seattle',

	HairMetal_title: 'Hair Metal',
	HairMetal_description: 'School of Rock Seattle',

	TalentShow2023_title: 'Show de Talentos 2023',
	TalentShow2023_description: 'Seattle Salmon Bay K-8',

	Deftones_title: 'Deftones',
	Deftones_description: 'School of Rock Seattle',

	SeasonPreviewSpring2023_title: 'Prévia da Temporada Primavera de 2023',
	SeasonPreviewSpring2023_description: 'School of Rock Seattle',

	Ramones_title: 'Os Ramones',
	Ramones_description: 'School of Rock Seattle',

	Soundtrack_title: 'Show da Trilha Sonora',
	Soundtrack_description: 'School of Rock Seattle',

	PopPunkVsEmo_title: 'Pop Punk Vs. Emo',
	PopPunkVsEmo_description: 'School of Rock Seattle',

	_90sNostalgia_title: 'Nostalgia dos anos 90',
	_90sNostalgia_description: 'School of Rock Seattle',

	TalentShow2022_title: 'Show de Talentos 2022',
	TalentShow2022_description: 'Seattle Salmon Bay K-8',

	// PRESS PAGE

	b_watch_here: 'Veja aqui',

	the_daly_migs_show_interview_title: 'Entrevista com The Daly Migs Show',
	the_daly_migs_show_interview_description: 'Entrevista sobre ser convidado para subir ao palco com Godsmack',

	alizee_defan_07_02_2024_title: 'Entrevista com "Alizee Defan"',
	alizee_defan_07_02_2024_description: 'Entrevista exclusiva',

	jaime_mendez_news_06_26_2024_title: 'Entrevista "Jaime Mendez News"',
	jaime_mendez_news_06_26_2024_description: 'Notícias locais de Seattle em espanhol',

	bien_informado_03_22_2023_title: 'Artigo de "Bien Informado"',
	bien_informado_03_22_2023_description: 'Artigo do site de notícias argentino sobre Victoria',

	wave_potter_reaction_01_30_2023_title: 'A reação de Wave Potter',
	wave_potter_reaction_01_30_2023_description: 'Wave Potter reage ao cover de Victoria Lux Æterna do Metallica',
	
	// ABOUT PAGE

	b_back: 'Volte',

	t_title: 'Victoria Acosta',
	t_pronouns: '(ela)',

	b_read_more: 'Leia mais',
	b_read_less: 'Leia menos',
	
	t_about: `
Conheça Victoria Acosta, a eletrizante baterista de {age} anos, cujo espírito vibrante e paixão implacável pela música estão conquistando o mundo! Nascida em El Paso, Texas, com raízes em Ciudad Juárez, México, e uma infância passada nas movimentadas ruas de Guadalajara, Victoria agora mora em Seattle, Washington, e continua a inspirar o público com seu talento excepcional e dedicação à sua arte.

Sua fascinação pela bateria começou aos quatro anos de idade. Enquanto a maioria das crianças brincava com brinquedos, esse talento notável já estava batendo ritmos e declarando à sua família que a bateria era seu instrumento favorito.

Em 13 de setembro de 2018, seu sonho se tornou realidade quando ela desembalou seu primeiro kit de bateria, e desde então não há nada que a pare! Inscreveu-se na renomada Musicalerias Academy em Guadalajara, mergulhando nos cursos formais e dominando a arte do ritmo como uma verdadeira estrela do rock.

Sua história deu um novo rumo quando sua família se mudou para Seattle em 10 de outubro de 2019. Inabalável, ela continuou seus estudos online, provando que a distância não é barreira para uma musicista apaixonada. Seu grande momento de virada aconteceu em 29 de maio de 2022, quando fez uma aula experimental na icônica School of Rock. Enquanto a maioria de seus colegas começava no programa básico Rock 101, o talento de Victoria brilhou intensamente, garantindo um lugar cobiçado no programa avançado Performers Program. Desde então, ela tem conquistado os palcos em {shows} apresentações ao vivo com sua energia contagiante e presença magnética no palco.

Mas isso é só o começo! Victoria não é apenas uma intérprete, ela também é uma sensação nas redes sociais, tendo gravado centenas de vídeos cativantes que mostram sua habilidade impressionante na bateria. Em 13 de junho de 2024, ela fez sucesso como baterista convidada para o aclamado compositor italiano Alberto Rigonni no Song Symposium para o álbum Nemesis Call, um marco em sua ascensão!

Em uma emocionante conquista, Victoria assinou um contrato de patrocínio com a Soultone Cymbals em 12 de setembro de 2024, consolidando seu lugar entre a elite dos bateristas e levando seu som a um novo nível.

Como se isso não fosse suficiente, Victoria ampliou seus horizontes musicais ao aprender violino no 4º ano. Em 13 de maio de 2024, ela se juntou à World Youth Orchestra e teve a honra de se apresentar ao lado do renomado duo Black Violin no Paramount Theatre, encantando uma plateia de 2.000 pessoas com seu talento extraordinário.

Sua jornada não parou por aí! Em 29 de agosto de 2024, ela se juntou à Ballard Civic Orchestra como percussionista, fazendo sua estreia no Town Hall de Seattle durante a celebração do Dia da Independência do México diante de quase 1.000 fãs entusiasmados.

Os reconhecimentos de Victoria são tão impressionantes quanto suas apresentações. Ela ganhou o Gold Prize e o prêmio de Técnica Fantástica no London Young Musician Contest 2022 e foi homenageada com o Annual Star Musician Award e o Annual Outstanding Drummer Special Award no London Young Musician of the Year 2023.

Agora, bem estabelecida em Seattle, Victoria pratica todos os dias, ultrapassando os limites de suas habilidades na bateria e no violino. Com os olhos voltados para um futuro emocionante na música, essa jovem artista dinâmica está apenas começando. Prepare-se para ser inspirado por Victoria Acosta, onde o ritmo encontra a paixão e os sonhos se tornam realidade!
`,

	// PRESS PAGE

	t_footer: 'Todos os direitos reservados',

	// SOULTONE: 

	b_visit_artist_page: 'Visite minha página de artista'

};

export default pt;
